import { zodResolver } from "@hookform/resolvers/zod";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import Input from "components/Input";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { Routes } from "utils/routes";
import Link from "next/link";
import { api } from "utils/api";
import Spinner from "./Spinner";
import * as Sentry from "@sentry/nextjs";

interface Props {
  signup?: boolean;
  redirectOnLogin?: boolean;
  inline?: boolean;
  onSignup?: (userId: string, email?: string) => void;
}

const DataSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, "Required"),
  confirmPassword: z.string().min(1, "Required").optional(),
});

type Data = z.infer<typeof DataSchema>;

export default function Authentication(props: Props) {
  const { redirectOnLogin = true, inline, onSignup } = props;

  const router = useRouter();
  const supabase = useSupabaseClient();

  const user = useUser();

  const [signup, setSignup] = useState(props.signup);

  const { register, handleSubmit, control, setError } = useForm<Data>({
    resolver: zodResolver(DataSchema),
  });
  const { errors } = useFormState({ control });

  useEffect(() => {
    if (user && redirectOnLogin) {
      router.push(Routes.HOME);
    }
  }, [user, router, redirectOnLogin]);

  const hubspotMutation = api.accounts.createHubspotContact.useMutation();

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      switch (event) {
        case "SIGNED_IN":
          await hubspotMutation.mutateAsync();
          if (session) {
            Sentry.setUser({ email: session.user.email, id: session.user.id });
          }
          break;

        case "SIGNED_OUT":
          break;

        case "USER_UPDATED":
          toast.success("Password updated!");
          router.push(Routes.SETTINGS);

          break;
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: Data) => {
    setLoading(true);
    if (signup) {
      if (data.confirmPassword !== data.password) {
        setLoading(false);
        return setError("confirmPassword", {
          message: "Passwords do not match.",
        });
      }

      try {
        const res = await supabase.auth.signInWithPassword(data);

        if (res.error) {
          throw Error();
        } else {
          toast.success("Logged in!");
        }
      } catch (err) {
        const res = await supabase.auth.signUp(data);

        if (res.error) {
          toast.error(res.error.message);
        } else {
          toast.success(
            "Account created! Check your email for a link to complete your account setup."
          );

          if (onSignup && res.data.user)
            onSignup(res.data.user.id, res.data.user.email);

          if (redirectOnLogin) {
            router.push(Routes.PROFILE);
          }
        }
      }
    } else {
      const res = await supabase.auth.signInWithPassword(data);

      if (res.error) {
        toast.error(res.error.message);
      } else {
        toast.success("Logged in!");
      }
    }

    setLoading(false);
  };

  const signInGoogle = async () => {
    const res = await supabase.auth.signInWithOAuth({
      provider: "google",
    });

    if (res.error) {
      toast.error(res.error.message);
    }
  };

  return (
    <div className="mx-auto flex w-full max-w-lg flex-1 flex-col items-center justify-center">
      <button
        onClick={signInGoogle}
        className="rounded border-2 border-theme-dark-blue px-4 py-3 text-theme-dark-blue"
      >
        Sign in with Google
      </button>
      <div className="my-10 flex w-full items-center space-x-2 text-gray-600">
        <div className="h-0.5 flex-1 bg-gray-200" />
        <span>Or</span>
        <div className="h-0.5 flex-1 bg-gray-200" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <label htmlFor="email" className="mb-2 block text-xs opacity-75">
          Email
        </label>
        <Input
          {...register("email")}
          className="w-full"
          error={errors.email?.message}
          type="email"
        />
        <label
          htmlFor="password"
          className="mb-2 mt-8 block text-xs opacity-75"
        >
          Password
        </label>
        <Input
          {...register("password")}
          className="w-full"
          error={errors.password?.message}
          type="password"
        />
        {signup && (
          <>
            <label
              htmlFor="confirmPassword"
              className="mb-2 mt-8 block text-xs opacity-75"
            >
              Confirm Password
            </label>
            <Input
              {...register("confirmPassword")}
              className="w-full"
              error={errors.confirmPassword?.message}
              type="password"
            />
          </>
        )}
        <button
          type="submit"
          className="mt-4 flex w-full justify-center rounded bg-theme-blue px-4 py-3 text-white"
        >
          {loading ? (
            <Spinner className="h-7 w-7 text-white" />
          ) : signup ? (
            "Join"
          ) : (
            "Log In"
          )}
        </button>
      </form>
      {inline ? (
        <button
          type="button"
          onClick={() => setSignup(!signup)}
          className="mt-2 text-sm text-theme-blue underline"
        >
          {signup
            ? "Already have an account? Log in"
            : "Don't have an account? Join now"}
        </button>
      ) : (
        <Link
          href={signup ? Routes.LOGIN : Routes.JOIN}
          className="mt-2 text-sm text-theme-blue underline"
        >
          {signup
            ? "Already have an account? Log in"
            : "Don't have an account? Join now"}
        </Link>
      )}
      {!signup && !inline && (
        <Link
          href={Routes.RESET_PASSWORD}
          className="mt-2 text-sm text-theme-blue underline"
        >
          Forgot password
        </Link>
      )}
    </div>
  );
}
