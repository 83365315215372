/* eslint-disable react/display-name */
import React from "react";
import { twMerge } from "tailwind-merge";
import cx from "classnames";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { error, ...rest } = props;

  const className = twMerge(
    `rounded border border-gray-300 disabled:opacity-50 py-3 px-4 ${cx({
      "ring-2 ring-red-500": error,
    })}`,
    props.className
  );

  return (
    <div className="w-full">
      <input {...rest} ref={ref} className={className} />
      {error && <div className="mt-2 text-red-500">{error}</div>}
    </div>
  );
});

export default Input;
