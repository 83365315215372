import Authentication from "components/Authentication";
import PublicPage from "components/PublicPage";
import React from "react";

export default function LoginPage() {
  return (
    <PublicPage
      metadata={{
        title: "Log In | Pipers For Hire",
        description:
          "Log in to the fastest growing site for bagpipers looking to book more gigs. Get started for free or explore our membership plans for more features.",
        url: "https://pipersforhire.com/login",
      }}
    >
      <Authentication />
    </PublicPage>
  );
}
